body {
  background-color: rgb(40, 37, 53);
  background: #0f2027;
  background: -webkit-linear-gradient(to right, #2c5364, #203a43, #0f2027);
  background: linear-gradient(to right, #2c5364, #203a43, #142b35);
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 2px 5px;
}
/* bg animation */
.area {
  width: 100%;
  height: 100vh;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

/* profile dropdown */
.dropdown .profile-image.show img {
  border-color: #2c5364 !important;
}
.dropdown .dropdown-menu {
  position: absolute;
  width: 150px;
  right: 0;
  background-color: #2c5364;
  padding: 5px 0;
  border-radius: 10px;
  display: none;
  text-align: left;
  z-index: 10;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
}
.dropdown .dropdown-menu.show::before {
  content: "";
  position: absolute;
  top: -5px;
  right: 19px;
  transform: rotate(45deg);
  width: 15px;
  height: 15px;
  background-color: #2c5364;
  z-index: -1;
}
.dropdown .dropdown-menu.show {
  display: block;
}
.dropdown .dropdown-item {
  padding: 10px 15px;
}
.dropdown .dropdown-item:hover {
  background-color: #091318;
}
.dropdown .dropdown-item svg {
  position: relative;
  top: 2px;
  margin-right: 15px;
}

.loader {
  border: 2px solid grey;
  border-top-color: white;
  width: 15px;
  height: 15px;
  padding: 15px;
  border-radius: 50%;
  animation: rotate 1.7s infinite linear;
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
.chatArea {
  padding: 10px;
  padding-bottom: 20px;
  height: 100%;
  max-height: calc(100vh - 140px);
  overflow-y: scroll;
}
.chatArea.replyingTo {
  max-height: calc(100vh - 190px);
}

.chatArea::-webkit-scrollbar {
  width: 0.35rem;
}

.chatArea::-webkit-scrollbar-track {
  background: #1e1e24;
}

.chatArea::-webkit-scrollbar-thumb {
  background: #2c5364;
  border-radius: 100vh;
  border: 2px solid #1e1e24;
}

.signInButton,
.sendButton {
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 10px;
  padding: 15px 25px 18px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  font-size: 1.25rem;
}
.signInButton .loading {
  margin: 0 auto;
  border: 2px solid grey;
  border-top-color: white;
  width: 15px;
  height: 15px;
  padding: 15px;
  border-radius: 50%;
  animation: rotate 1.7s infinite linear;
}

button.signOut {
  font-size: 1rem;
  background-color: #2c5364;
  padding: 10px 20px;
}
button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.form {
  height: 70px;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  font-size: 1.5rem;
  background-color: #091318;
}

.form button {
  width: clamp(20%, 100px);
  border-radius: 0;
  background-color: #2c5364;
}

#messageInput {
  line-height: 1.5;
  width: 100%;
  font-size: 1.5rem;
  background-color: #091318;
  color: white;
  outline: none;
  border: none;
  padding: 0 10px;
}

.sign-in {
  color: #282c34;
  background: white;
  max-width: 400px;
  margin: 0 auto;
}

ul,
li {
  text-align: left;
  list-style: none;
}

.message-container {
  display: flex;
  align-items: center;
  gap: 6px;
}
.message-container svg {
  opacity: 0;
  color: #091318 !important;
  transition: all 0.3s cubic-bezier(0.83, -0.53, 0.55, 1.8);
  cursor: pointer;
}
.message-container.sent:hover svg {
  margin-right: 20px;
  opacity: 1;
}
.message-container.received:hover svg {
  margin-left: 20px;
  opacity: 1;
  color: #091318 !important;
}
.message,
.reply-message {
  max-width: 500px;
  min-width: 60px;
  width: fit-content;
  margin-bottom: 12px;
  line-height: 24px;
  padding: 10px 20px 10px 20px;
  border-radius: 20px;
  position: relative;
  color: white;
  text-align: left;
  overflow-wrap: break-word;
}
.reply-message {
  position: relative;
  border-radius: 20px;
  padding: 7px 14px;
  margin-bottom: 5px;
  font-size: 0.9rem;
}

.sent {
  flex-direction: row-reverse;
}
.sent .message {
  color: white;
  background: #2c5364;
  align-self: flex-end;
}
.received .message {
  background: #0f2027;
  color: white;
}
.reply-message.received {
  background: #1a3642;
  color: white;
}
.reply-message.sent {
  background: #0f2027;
  color: white;
  margin-left: auto;
}
.message div {
  font-size: 0.8rem;
  opacity: 0.5;
  text-align: right;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 2px;
  white-space: nowrap;
  line-height: 1;
}
.profile-container {
  height: 100vh;
  width: 100vw;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  display: none;
}
.profile-container.visible {
  display: flex;
}

.profile-container .profile {
  position: relative;
  background-color: #2c5364;
  padding: 50px;
  border-radius: 20px;
}
.profile-container .profile svg {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.profile-container.visible .profile {
  animation: fadeIn 3s forwards cubic-bezier(0.075, 0.82, 0.165, 1);
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.profile-container .profile img {
  width: 70px;
  height: 70px;
}
.profile-container .profile .lowercase {
  text-transform: lowercase;
}
.profile-container .profile .email {
  font-size: 0.8rem;
  margin: 5px;
}
@media (max-width: 600px) {
  .message {
    max-width: 400px;
  }
  input {
    font-size: 1.25rem;
  }
}
@media (max-width: 490px) {
  .message {
    max-width: 300px;
  }
  .message-container img {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 420px) {
  .message {
    max-width: 280px;
  }
}

@media (max-width: 370px) {
  .message {
    max-width: 250px;
  }
}
