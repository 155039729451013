@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background-color: #282c34;
  font-family: "Roboto", sans-serif;
  max-height: 100vh;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
